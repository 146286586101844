var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
import Dialog from 'rc-dialog';
import useClosable from '../_util/hooks/useClosable';
import { getTransitionName } from '../_util/motion';
import { canUseDocElement } from '../_util/styleChecker';
import { devUseWarning } from '../_util/warning';
import { ConfigContext } from '../config-provider';
import { NoFormStyle } from '../form/context';
import { NoCompactStyle } from '../space/Compact';
import { usePanelRef } from '../watermark/context';
import { Footer, renderCloseIcon } from './shared';
import useStyle from './style';
let mousePosition;
// ref: https://github.com/ant-design/ant-design/issues/15795
const getClickPosition = (e) => {
    mousePosition = {
        x: e.pageX,
        y: e.pageY,
    };
    // 100ms 内发生过点击事件，则从点击位置动画展示
    // 否则直接 zoom 展示
    // 这样可以兼容非点击方式展开
    setTimeout(() => {
        mousePosition = null;
    }, 100);
};
// 只有点击事件支持从鼠标位置动画展开
if (canUseDocElement()) {
    document.documentElement.addEventListener('click', getClickPosition, true);
}
const Modal = (props) => {
    var _a;
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, modal, } = React.useContext(ConfigContext);
    const handleCancel = (e) => {
        const { onCancel } = props;
        onCancel === null || onCancel === void 0 ? void 0 : onCancel(e);
    };
    const handleOk = (e) => {
        const { onOk } = props;
        onOk === null || onOk === void 0 ? void 0 : onOk(e);
    };
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning('Modal');
        warning.deprecated(!('visible' in props), 'visible', 'open');
    }
    const { prefixCls: customizePrefixCls, className, rootClassName, open, wrapClassName, centered, getContainer, closeIcon, closable, focusTriggerAfterClose = true, style, 
    // Deprecated
    visible, width = 520, footer } = props, restProps = __rest(props, ["prefixCls", "className", "rootClassName", "open", "wrapClassName", "centered", "getContainer", "closeIcon", "closable", "focusTriggerAfterClose", "style", "visible", "width", "footer"]);
    const prefixCls = getPrefixCls('modal', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    // Style
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const wrapClassNameExtended = classNames(wrapClassName, {
        [`${prefixCls}-centered`]: !!centered,
        [`${prefixCls}-wrap-rtl`]: direction === 'rtl',
    });
    const dialogFooter = footer !== null && (React.createElement(Footer, Object.assign({}, props, { onOk: handleOk, onCancel: handleCancel })));
    const [mergedClosable, mergedCloseIcon] = useClosable(closable, closeIcon, (icon) => renderCloseIcon(prefixCls, icon), React.createElement(CloseOutlined, { className: `${prefixCls}-close-icon` }), true);
    // ============================ Refs ============================
    // Select `ant-modal-content` by `panelRef`
    const panelRef = usePanelRef(`.${prefixCls}-content`);
    // =========================== Render ===========================
    return wrapSSR(React.createElement(NoCompactStyle, null,
        React.createElement(NoFormStyle, { status: true, override: true },
            React.createElement(Dialog, Object.assign({ width: width }, restProps, { getContainer: getContainer === undefined ? getContextPopupContainer : getContainer, prefixCls: prefixCls, rootClassName: classNames(hashId, rootClassName), wrapClassName: wrapClassNameExtended, footer: dialogFooter, visible: open !== null && open !== void 0 ? open : visible, mousePosition: (_a = restProps.mousePosition) !== null && _a !== void 0 ? _a : mousePosition, onClose: handleCancel, closable: mergedClosable, closeIcon: mergedCloseIcon, focusTriggerAfterClose: focusTriggerAfterClose, transitionName: getTransitionName(rootPrefixCls, 'zoom', props.transitionName), maskTransitionName: getTransitionName(rootPrefixCls, 'fade', props.maskTransitionName), className: classNames(hashId, className, modal === null || modal === void 0 ? void 0 : modal.className), style: Object.assign(Object.assign({}, modal === null || modal === void 0 ? void 0 : modal.style), style), panelRef: panelRef })))));
};
export default Modal;
