import React, { createRef, forwardRef, useContext } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import omit from 'rc-util/lib/omit';
import throttleByAnimationFrame from '../_util/throttleByAnimationFrame';
import { ConfigContext } from '../config-provider';
import useStyle from './style';
import { getFixedBottom, getFixedTop, getTargetRect } from './utils';
const TRIGGER_EVENTS = [
    'resize',
    'scroll',
    'touchstart',
    'touchmove',
    'touchend',
    'pageshow',
    'load',
];
function getDefaultTarget() {
    return typeof window !== 'undefined' ? window : null;
}
var AffixStatus;
(function (AffixStatus) {
    AffixStatus[AffixStatus["None"] = 0] = "None";
    AffixStatus[AffixStatus["Prepare"] = 1] = "Prepare";
})(AffixStatus || (AffixStatus = {}));
class InternalAffix extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            status: AffixStatus.None,
            lastAffix: false,
            prevTarget: null,
        };
        this.placeholderNodeRef = createRef();
        this.fixedNodeRef = createRef();
        this.addListeners = () => {
            const targetFunc = this.getTargetFunc();
            const target = targetFunc === null || targetFunc === void 0 ? void 0 : targetFunc();
            const { prevTarget } = this.state;
            if (prevTarget !== target) {
                TRIGGER_EVENTS.forEach((eventName) => {
                    prevTarget === null || prevTarget === void 0 ? void 0 : prevTarget.removeEventListener(eventName, this.lazyUpdatePosition);
                    target === null || target === void 0 ? void 0 : target.addEventListener(eventName, this.lazyUpdatePosition);
                });
                this.updatePosition();
                this.setState({ prevTarget: target });
            }
        };
        this.removeListeners = () => {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            const { prevTarget } = this.state;
            const targetFunc = this.getTargetFunc();
            const newTarget = targetFunc === null || targetFunc === void 0 ? void 0 : targetFunc();
            TRIGGER_EVENTS.forEach((eventName) => {
                newTarget === null || newTarget === void 0 ? void 0 : newTarget.removeEventListener(eventName, this.lazyUpdatePosition);
                prevTarget === null || prevTarget === void 0 ? void 0 : prevTarget.removeEventListener(eventName, this.lazyUpdatePosition);
            });
            this.updatePosition.cancel();
            // https://github.com/ant-design/ant-design/issues/22683
            this.lazyUpdatePosition.cancel();
        };
        this.getOffsetTop = () => {
            const { offsetBottom, offsetTop } = this.props;
            return offsetBottom === undefined && offsetTop === undefined ? 0 : offsetTop;
        };
        this.getOffsetBottom = () => this.props.offsetBottom;
        // =================== Measure ===================
        this.measure = () => {
            const { status, lastAffix } = this.state;
            const { onChange } = this.props;
            const targetFunc = this.getTargetFunc();
            if (status !== AffixStatus.Prepare ||
                !this.fixedNodeRef.current ||
                !this.placeholderNodeRef.current ||
                !targetFunc) {
                return;
            }
            const offsetTop = this.getOffsetTop();
            const offsetBottom = this.getOffsetBottom();
            const targetNode = targetFunc();
            if (targetNode) {
                const newState = {
                    status: AffixStatus.None,
                };
                const placeholderRect = getTargetRect(this.placeholderNodeRef.current);
                if (placeholderRect.top === 0 &&
                    placeholderRect.left === 0 &&
                    placeholderRect.width === 0 &&
                    placeholderRect.height === 0) {
                    return;
                }
                const targetRect = getTargetRect(targetNode);
                const fixedTop = getFixedTop(placeholderRect, targetRect, offsetTop);
                const fixedBottom = getFixedBottom(placeholderRect, targetRect, offsetBottom);
                if (fixedTop !== undefined) {
                    newState.affixStyle = {
                        position: 'fixed',
                        top: fixedTop,
                        width: placeholderRect.width,
                        height: placeholderRect.height,
                    };
                    newState.placeholderStyle = {
                        width: placeholderRect.width,
                        height: placeholderRect.height,
                    };
                }
                else if (fixedBottom !== undefined) {
                    newState.affixStyle = {
                        position: 'fixed',
                        bottom: fixedBottom,
                        width: placeholderRect.width,
                        height: placeholderRect.height,
                    };
                    newState.placeholderStyle = {
                        width: placeholderRect.width,
                        height: placeholderRect.height,
                    };
                }
                newState.lastAffix = !!newState.affixStyle;
                if (onChange && lastAffix !== newState.lastAffix) {
                    onChange(newState.lastAffix);
                }
                this.setState(newState);
            }
        };
        this.prepareMeasure = () => {
            // event param is used before. Keep compatible ts define here.
            this.setState({
                status: AffixStatus.Prepare,
                affixStyle: undefined,
                placeholderStyle: undefined,
            });
            // Test if `updatePosition` called
            if (process.env.NODE_ENV === 'test') {
                const { onTestUpdatePosition } = this.props;
                onTestUpdatePosition === null || onTestUpdatePosition === void 0 ? void 0 : onTestUpdatePosition();
            }
        };
        this.updatePosition = throttleByAnimationFrame(() => {
            this.prepareMeasure();
        });
        this.lazyUpdatePosition = throttleByAnimationFrame(() => {
            const targetFunc = this.getTargetFunc();
            const { affixStyle } = this.state;
            // Check position change before measure to make Safari smooth
            if (targetFunc && affixStyle) {
                const offsetTop = this.getOffsetTop();
                const offsetBottom = this.getOffsetBottom();
                const targetNode = targetFunc();
                if (targetNode && this.placeholderNodeRef.current) {
                    const targetRect = getTargetRect(targetNode);
                    const placeholderRect = getTargetRect(this.placeholderNodeRef.current);
                    const fixedTop = getFixedTop(placeholderRect, targetRect, offsetTop);
                    const fixedBottom = getFixedBottom(placeholderRect, targetRect, offsetBottom);
                    if ((fixedTop !== undefined && affixStyle.top === fixedTop) ||
                        (fixedBottom !== undefined && affixStyle.bottom === fixedBottom)) {
                        return;
                    }
                }
            }
            // Directly call prepare measure since it's already throttled.
            this.prepareMeasure();
        });
    }
    getTargetFunc() {
        const { getTargetContainer } = this.context;
        const { target } = this.props;
        if (target !== undefined) {
            return target;
        }
        return getTargetContainer !== null && getTargetContainer !== void 0 ? getTargetContainer : getDefaultTarget;
    }
    // Event handler
    componentDidMount() {
        // [Legacy] Wait for parent component ref has its value.
        // We should use target as directly element instead of function which makes element check hard.
        this.timer = setTimeout(this.addListeners);
    }
    componentDidUpdate(prevProps) {
        this.addListeners();
        if (prevProps.offsetTop !== this.props.offsetTop ||
            prevProps.offsetBottom !== this.props.offsetBottom) {
            this.updatePosition();
        }
        this.measure();
    }
    componentWillUnmount() {
        this.removeListeners();
    }
    // =================== Render ===================
    render() {
        const { affixStyle, placeholderStyle } = this.state;
        const { affixPrefixCls, rootClassName, children } = this.props;
        const className = classNames(affixStyle && rootClassName, {
            [affixPrefixCls]: !!affixStyle,
        });
        let props = omit(this.props, [
            'prefixCls',
            'offsetTop',
            'offsetBottom',
            'target',
            'onChange',
            'affixPrefixCls',
            'rootClassName',
        ]);
        // Omit this since `onTestUpdatePosition` only works on test.
        if (process.env.NODE_ENV === 'test') {
            props = omit(props, ['onTestUpdatePosition']);
        }
        return (React.createElement(ResizeObserver, { onResize: this.updatePosition },
            React.createElement("div", Object.assign({}, props, { ref: this.placeholderNodeRef }),
                affixStyle && React.createElement("div", { style: placeholderStyle, "aria-hidden": "true" }),
                React.createElement("div", { className: className, ref: this.fixedNodeRef, style: affixStyle },
                    React.createElement(ResizeObserver, { onResize: this.updatePosition }, children)))));
    }
}
InternalAffix.contextType = ConfigContext;
const Affix = forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, rootClassName } = props;
    const { getPrefixCls } = useContext(ConfigContext);
    const affixPrefixCls = getPrefixCls('affix', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(affixPrefixCls);
    const AffixProps = Object.assign(Object.assign({}, props), { affixPrefixCls, rootClassName: classNames(rootClassName, hashId) });
    return wrapSSR(React.createElement(InternalAffix, Object.assign({}, AffixProps, { ref: ref })));
});
if (process.env.NODE_ENV !== 'production') {
    Affix.displayName = 'Affix';
}
export default Affix;
