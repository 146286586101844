import * as React from 'react';
import rcWarning, { resetWarned } from 'rc-util/lib/warning';
export { resetWarned };
export function noop() { }
// eslint-disable-next-line import/no-mutable-exports
let warning = noop;
if (process.env.NODE_ENV !== 'production') {
    warning = (valid, component, message) => {
        rcWarning(valid, `[antd: ${component}] ${message}`);
        // StrictMode will inject console which will not throw warning in React 17.
        if (process.env.NODE_ENV === 'test') {
            resetWarned();
        }
    };
}
export const WarningContext = React.createContext({});
/**
 * This is a hook but we not named as `useWarning`
 * since this is only used in development.
 * We should always wrap this in `if (process.env.NODE_ENV !== 'production')` condition
 */
export const devUseWarning = process.env.NODE_ENV !== 'production'
    ? (component) => {
        const { deprecated } = React.useContext(WarningContext);
        const typeWarning = (valid, type, message) => {
            if (deprecated !== false || type !== 'deprecated') {
                warning(valid, component, message);
            }
        };
        typeWarning.deprecated = (valid, oldProp, newProp, message) => {
            typeWarning(valid, 'deprecated', `\`${oldProp}\` is deprecated. Please use \`${newProp}\` instead.${message ? ` ${message}` : ''}`);
        };
        return typeWarning;
    }
    : () => {
        const noopWarning = () => { };
        noopWarning.deprecated = noop;
        return noopWarning;
    };
export default warning;
